import { dbGetSegment } from './api-functions';

/** Metadata */
import metadata from '../../metadata';

/**
 * Segment params are used to identify what segment does a particular user belongs to
 * @param {object} user 
 * @returns 
 */
export default async function getSegmentParams(user) {
    /***************/
    /** Checking user data */
    let params;
    
    try { params = JSON.parse(user?.params); } 
    catch (error) { params = {}; }

    let depositMinAmount = user?.depositMinAmount || 0;

    /***************/
    /** Preparing segment variables */
    let id = '';
    let countries = '';

    /*******************/
    /** Return if user is logged in and no params are present */
    if (Object.keys(user)?.length > 0 && Object.keys(params)?.length === 0) {
        return {
            id,
            params: JSON.stringify(params),
            depositMinAmount,
            countries
        }
    };

    /*******************/
    /** Get all active parameters for a logged out user */
    if (Object.keys(user)?.length === 0) {
        params = getActiveParams();
    }

    /*******************/
    /** Return if there are no active parameters */
    if (Object.keys(params)?.length === 0) {
        return {
            id,
            params: JSON.stringify(params),
            depositMinAmount,
            countries
        }
    };

    /******************/
    /** Get the segment */
    try {
        const identifiers = Array.from(Object.values(params))
        const response = await dbGetSegment(identifiers);
        const segment = response?.data?.docs[0];

        if (segment) {
            id = segment.id;
            countries = segment.countries || '';
            depositMinAmount = user?.depositMinAmount || segment.value || 0;
        };
    } catch (error) {
        console.log(`Error, getSegmentParams, dbGetSegment, ${error}`)
    }

    /******************/
    /** Return object */
    return {
        id,
        params: JSON.stringify(params),
        depositMinAmount,
        countries
    }
}

function getActiveParams() {
    try {
        /*************************/
        /** Check if there is a brand specific parameter id */
        if (!metadata?.brandParamsId) {
            console.log('Error, no brandParamsId');
            return {};
        }

        /*************************/
        /** Get parameters from URL */
        const urlParams = new URLSearchParams(window.location.search);
        let params = Object.fromEntries(urlParams);

        /*************************/
        /** Add URL params to local storage */
        if (Object.keys(params).length > 0) {
            window.localStorage.setItem(`${metadata?.brandParamsId}_params`, JSON.stringify(params));
        }

        /*************************/
        /** If there are no params in the url, get params from the local storage */
        if (Object.keys(params).length === 0) {
            let stringParams = window.localStorage.getItem(`${metadata?.brandParamsId}_params`) || '{}';
            params = JSON.parse(stringParams);
        }

        /*************************/
        /** Response */
        return params;
    } catch (error) {
        console.log(`Error, getActiveParamsJSONString, could not get params, ${error}`);
        return {};
    }
}