import Axios from 'axios';

/** Metadata */
import metadata from '../../metadata';

/************************/
/** Admin URL */
const apiURL = process.env.REACT_APP_PAYLOAD_URL;

/************************/
/** Language */
let language = localStorage.getItem("selected_language") || window?.navigator?.language?.split('-')[0];

if (!metadata?.languages?.includes(language)) {
    language = metadata.default_language;
}

/************************/
/** Transalations */
const messages = {
    account_blocked: {
        "en": "Your account has been suspended. For any inquiries please our support team"
    }
}

/************************/
/** User functions */
export const dbUserFromToken = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/players/me`
    }).then(res => {
        if (res.data.user && res?.data?.user?.isActive) {
            dbUpdateSessionLogin();
            return res;
        }
        else {
            return { errors: [{ message: messages?.account_blocked[language] }] }
        }
    });
}

export const dbLogin = (email, password) => {
    return Axios({
        method: 'post',
        data: {
            email: email,
            password: password
        },
        withCredentials: true,
        url: `${apiURL}/api/players/login`
    }).then(res => {
        if (res.data.user && res?.data?.user?.isActive) {
            dbUpdateSessionLogin();
            return res;
        }
        else {
            return { errors: [{ message: messages?.account_blocked[language] }] }
        }
    });
}

export const dbLogout = async () => {

    /** Register logout */
    try {
        await dbUpdateSessionLogout()
    } catch (error) { }

    /** Return logout function */
    try {
        await Axios({
            method: 'post',
            withCredentials: true,
            url: `${apiURL}/api/players/logout`
        })
    } catch (error) {}
}

export const dbCheckEmailExists = (email) => {
    return Axios({
        method: 'post',
        data: { email },
        withCredentials: true,
        url: `${apiURL}/utility/check-email-exists`
    })
}

export const dbVerifyTurnstile = (token) => {
    return Axios({
        method: 'post',
        data: { token },
        withCredentials: true,
        url: `${apiURL}/utility/verify-turnstile`
    })
}

export const dbRegister = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/api/players`
    })
}

export const dbForgotPassword = (email) => {
    return Axios({
        method: 'post',
        data: {
            email: email,
            origin: window.location.origin + window.location.pathname
        },
        withCredentials: true,
        url: `${apiURL}/api/players/forgot-password`
    })
}

export const dbResetPassword = (token, password) => {
    return Axios({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            token: token,
            password: password
        },
        withCredentials: true,
        url: `${apiURL}/api/players/reset-password`
    })
}

export const dbVerifyEmail = (token) => {
    return Axios({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        url: `${apiURL}/api/players/verify/${token}`
    })
}

export const dbUpdatePlayedGames = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/utility/update-played-games`
    })
}

export const dbUpdateSessionLogin = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/session-data/record-login-data`
    })
}

export const dbUpdateSessionLogout = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/session-data/record-logout-data`
    })
}

export const dbSendWelcomeEmail = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/email-client/send-welcome-email`
    })
}


/************************/
/** Content functions */
export const dbGetCountries = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/countries?limit=1000`
    })
}

export const dbGetGlobalContent = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/globals/global_content?depth=0`
    })
}

export const dbGetSegment = (array) => {
    const list = array.join(',');
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/segments?where[identifier][in]=${list}&depth=1`
    })
}

export const dbGetGames = (language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/game_categories?where[url][equals]=all_games&locale=${language}&limit=1`
    })
}

export const dbGetPromotions = (language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/promotions?where[isActive][equals]=true&limit=1000&locale=${language}`
    })
}

export const dbGetPromotion = (id) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/promotions?where[id][equals]=${id}&where[isActive][equals]=true&limit=1000`
    })
}

export const dbGetBonuses = (language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/bonuses?where[isActive][equals]=true&limit=1000&locale=${language}&sort=rank`
    })
}

export const dbGetGameCategories = (language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/game_categories?locale=${language}&limit=1000&depth=1`
    })
}

export const dbGetGameList = (array) => {
    const list = array.join(',');
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[id][in]=${list}&depth=1`
    })
}

export const dbGetGameUuidList = (array) => {
    const list = array.join(',');
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[uuid][in]=${list}&where[isActive][equals]=true&depth=1`
    })
}

export const dbGetGame = (id) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[isActive][equals]=true&where[id][equals]=${id}&limit=1`
    })
}

export const dbGetAllGames = (page) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[isActive][equals]=true&depth=0&limit=24&page=${page}&sort=providerRank`
    })

}

export const dbGetGameByTagAndProvider = (tag) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[tags][contains]=${tag}&where[isActive][equals]=true&depth=0&limit=1&sort=providerRank`
    })
}

export const dbGetGamesByTagAndProvider = (tag, page) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[tags][contains]=${tag}&where[isActive][equals]=true&depth=0&limit=24&page=${page}&sort=providerRank`
    })
}

export const dbSearchGames = (search, page) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[search][contains]=${search}&where[isActive][equals]=true&limit=24&page=${page}&sort=providerRank`
    })
}

export const dbGetGamesByProviderName = (name) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/api/games?where[provider][equals]=${name}&where[isActive][equals]=true&sort=providerRank&limit=1000`
    })
}

/************************/
/** Payment functions */

export const dbUpdateUserInfo = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/utility/update-user-info`
    })
}

export const dbUpdateRetentionNotifications = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/utility/update-retention-notifications`
    })
}

export const dbCloseAccount = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/utility/close-account`
    })
}

export const dbOpenAccount = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/utility/open-account`
    })
}

export const dbCancelBonus = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/bonuses/cancel-bonus`
    })
}

export const dbClaimBonusWinnings = (data) => {
    return Axios({
        method: 'post',
        data: {},
        withCredentials: true,
        url: `${apiURL}/bonuses/claim-bonus-winnings`
    })
}

/************************/
/** Payment functions */
export const dbCorefyDeposit = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/corefy/deposit`
    })
}

export const dbCorefyWithdraw = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/corefy/withdraw`
    })
}

/************************/
/** Game functions */
export const dbLaunchGame = (data) => {
    if (data.source === 'pragmatic-play') {
        return Axios({
            method: 'post',
            data: data,
            withCredentials: true,
            url: `${apiURL}/pragmatic-play/launch-game`
        })
    }

    if (data.source === 'gaming-construct') {
        return Axios({
            method: 'post',
            data: data,
            withCredentials: true,
            url: `${apiURL}/gconstruct/launch-game`
        })
    }

    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/slotegrator/launch-game`
    })
}


/************************/
/** Bonus functions */
export const dbAssignFreeBonus = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/bonuses/assign-free-bonus`
    })
}

export const dbAssignLimitedTimeFreeBonus = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/bonuses/assign-free-limited-bonus`
    })
}


/************************/
/** Freespin functions */
export const dbCheckAvailableFreespins = (data) => {
    return new Promise(async (resolve, reject) => {
        
        /************************/
        /** Slotegrator */
        let slotegratorResult;

        try {
            slotegratorResult = await Axios({
                method: 'post',
                data: data,
                withCredentials: true,
                url: `${apiURL}/slotegrator/freespins/get-current-campaign`
            })
        } catch (error) {
            console.log(`Error, dbCheckAvailableFreespins, Slotegrator, ${error}`)
        }

        /************************/
        /** Pragmatic */
        let pragmaticResult;

        try {
            pragmaticResult = await Axios({
                method: 'post',
                data: data,
                withCredentials: true,
                url: `${apiURL}/pragmatic-play/freespins/get`
            })
        } catch (error) {
            console.log(`Error, dbCheckAvailableFreespins, Pragmatic, ${error}`)
        }

        console.log(slotegratorResult?.data)
        /************************/
        /** Results */
        if (slotegratorResult?.data?.games?.length > 0) {
            console.log(slotegratorResult)
            resolve(slotegratorResult);
            return;
        }

        if (pragmaticResult?.data?.games?.length > 0) {
            resolve(pragmaticResult);
            return;
        }
    })
}


/************************/
/** Address functions */
export const dbAddressLookup = (params) => {
    return Axios({
        method: 'get',
        headers: {
            'x-api-key': process.env.REACT_APP_POSTGRID_API_KEY
        },
        url: `https://api.postgrid.com/v1/addver/completions?partialStreet=${params}&countryFilter=CA&provInsteadOfPC=true`
    })
}

export const dbAddressComplete = (data) => {
    return Axios({
        method: 'post',
        headers: {
            'x-api-key': process.env.REACT_APP_POSTGRID_API_KEY
        },
        data: data,
        url: `https://api.postgrid.com/v1/addver/completions?index=0`
    })
}


/************************/
/** IP functions */
export const dbGetIPData = () => {
    return Axios({
        method: 'get',
        url: `${apiURL}/session-data/get-ip-data`
    })
}


/************************/
/** Phone verification functions */
export const dbSendVerificationCode = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/phone-verification/send-code`
    })
}

export const dbCheckVerificationCode = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${apiURL}/phone-verification/check-code`
    })
}

/************************/
/** Error collection */
export const dbCreateError = (data) => {
    // try {
    //     Axios({
    //         method: 'post',
    //         data: data,
    //         withCredentials: true,
    //         url: `${apiURL}/api/errors`
    //     })
    // } catch (error) { }
}

/************************/
/** Bonus roll */
export const dbBonusRoll = (data) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/cashback-roll/roll`
    })
}

/************************/
/** Get active claim winnings check */
export const dbGetActiveClaimWinningsCheck = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/utility/get-active-claim-winnings-check`
    })
}

/************************/
/** Get active withdrawal */
export const dbGetActiveWithdrawal = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/utility/get-active-withdrawal`
    })
}

/************************/
/** Get allowed deposit amount */
export const dbGetDepositAllowedAmount = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${apiURL}/utility/get-deposit-allowed-amount`
    })
}